
<template>
  <div class="single-video-view">
    <div class="view-header">
      <validation-observer
        ref="single-video-filters-form"
        tag="form"
        class="search-form flex flex-align-items-center mb-4">
        <f-input-wrapper
          prependIcon="search"
          class="mr-2 full-width">
          <input
            type="search"
            v-model="filters.search"
            placeholder="Search Video Library"/>
        </f-input-wrapper>

        <div class="search-form-buttons flex ml-auto">
          <f-button
            theme="primary"
            :big="true"
            class="m2-1 mb-1-ms ml-0-sm text-nowrap"
            @click.prevent="validateFiltersForm">
            Search
          </f-button>
        </div>
      </validation-observer>

      <div class="flex flex-justify-content-between mt-4">
        <f-button
          theme="text-light"
          arrowBack
          @click.prevent="$router.go(-1)"
          class="mr-1">
          Back to list
        </f-button>
      </div>
    </div>

    <div
      v-if="loaded"
      class="single-video">
      <div class="video flex">
        <div class="viedo-wrapper">
          <video
            v-if="data.url"
            :id="'video-' + data.id"
            controls
            :type="data.file_type"
            @[isNotWatched&&`play`]="onVideoPlay"
            @[isNotWatched&&`playing`]="onVideoPlay"
            @[isNotWatched&&`pause`]="onVideoPause"
            @[isNotWatched&&`waiting`]="onVideoPause"
            @[isNotWatched&&`loadeddata`]="updateVideoDuration"
            @[isNotWatched&&`timeupdate`]="debounceUpdateViewTime">
            <source :src="data.url">
            Your browser does not support the video tag.
          </video>
          <div class="mt-2">Views: <strong>{{ data.views }}</strong></div>
          <p class="video-description mt-2">{{ data.description }}</p>
        </div>

        <div class="video-details">
          <h1 class="video-title heading-medium">{{ data.title }}</h1>
          <p class="video-author">{{ data.author }}</p>
          <p class="video-description">{{ data.about_you }}</p>

          <ul class="video-tags">
            <li
              v-for="(tag, tagIndex) in data.tags"
              :key="tagIndex"
              class="video-tags-item">
              {{ tag.name }}
            </li>
          </ul>
        </div>
      </div>

      <template v-if="data.similar_videos.length">
        <videos-slider
          :categoryLabel="'You may also like'"
          :videos="data.similar_videos"
          :windowWidth="windowWidth" />
      </template>
    </div>

    <div
      v-if="!loaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>
<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import FormUtils from '@/utils/FormUtils.js';
import VideosSlider from '@/components/layout/VideosSlider.vue';

export default {
  name: 'single-video',
  components: {
    'videos-slider': VideosSlider
  },
  data () {
    return {
      data: {
        id: 0,
        about_you: '',
        author: '',
        categories: [],
        description: '',
        duration: null,
        similar_videos: [],
        tags: [],
        title: '',
        url: null,
        views: 0
      },
      loaded: false,
      loadError: false,
      filters: {
        search: ''
      },
      windowWidth: 0,
      isPlaying: false,
      videoDuration: 0,
      videoPlayingFrom: null,
      timePlayed: 0,
      isNotWatched: true
    }
  },
  mounted() {
    this.data.id = parseFloat(this.$route.params.id);
    window.addEventListener('resize', this.debouncedResizeHandler);
    this.windowWidth = window.innerWidth
    this.loadItemData();
  },
  methods: {
    loadItemData () {
      this.loaded = false;

      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/member/video/get-signed-url/',
        instanceFields: [
          'about_you',
          'author',
          'categories',
          'description',
          'duration',
          'similar_videos',
          'tags',
          'title',
          'url',
          'views'
        ],
        loadedKey: 'loaded'
      });
    },
    validateFiltersForm () {
      FormUtils.validate(this.$refs['single-video-filters-form'], this.emitFilterListEvent)
    },
    emitFilterListEvent () {
      this.$router.push({
        path: '/videos',
        query: { search: this.filters.search }
      });
    },
    updateVideoDuration (e) {
      Vue.set(this, 'videoDuration', e.target.duration);
    },
    onVideoPlay () {
      Vue.set(this, 'videoPlayingFrom', new Date());
      Vue.set(this, 'isPlaying', true);
    },
    onVideoPause () {
      Vue.set(this, 'videoPlayingFrom', null);
      Vue.set(this, 'isPlaying', false);
    },
    debounceUpdateViewTime: debounce(function () {
      if (this.timePlayed > this.videoDuration * 0.2) {
        Vue.set(this, 'isNotWatched', null);

        this.$http.post('/api/member/video/update-counter', { id: this.data.id }).then((response) => {
          if (('success' in response.data) && response.data.success === 0) {
            console.log(response.data.message);
          }
        }).catch((error) => {
          console.log(error);
        });

        return;
      }

      if (this.isPlaying) {
        let currentPlayedFor = ((new Date().getTime() - this.videoPlayingFrom) / 1000);
        Vue.set(this, 'videoPlayingFrom', new Date());
        Vue.set(this, 'timePlayed', this.timePlayed + currentPlayedFor);
      }
    }, 250),
    debouncedResizeHandler: debounce(function () {
      this.windowWidth = window.innerWidth;
    }, 250),
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debouncedResizeHandler);
  }
}
</script>
